import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/features/userSlice";

function Footer() {
  const user = useSelector((state) => state.user || {}); // Ensure user is an object even if it's null
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(logout());
  }

  return (
    <div className="footer">
      <footer
        className="text-center text-lg-start bg-black text-light"
        style={{ paddingTop: "100px", paddingBottom: "80px" }}
      >
        <section
          className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
          style={{ maxWidth: "1440px", margin: "auto" }}
        >
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a
              href="https://t.me/hackworldgroup"
              className="me-2 ms-2 text-reset"
            >
              <i className="fab fa-telegram" style={{ fontSize: "40px" }}></i>{" "}
              Telegram
            </a>
          </div>
        </section>
        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © {new Date().getFullYear()} Copyright:
          <a className="text-reset fw-bold" href="https://hworld.me/">
            Hworld
          </a>
        </div>
      </footer>



      <div className="fixed-bottom">
        <div className="w-100 m-auto">

        {!user.isAdmin ? (
          <div className="d-flex align-items-center justify-content-evenly gap-6" style={{fontSize:"14px"}}>
            <Link to="/" className="text-white">
              <i className="fa-solid fa-house"></i>
              <p>Home</p>
            </Link>
            <Link to="/category/all" className="text-white">
              <i className="fa-solid fa-shop"></i>
              <p>Shop</p>
            </Link>

            {user.email && !user.isAdmin && (
              <LinkContainer to="/cart">
                <Nav.Link>
                  <i
                    className="fas fa-shopping-cart"
                    style={{ color: "white", fontSize:'14px' }}
                  ></i>
                  {user?.cart?.count > 0 && (
                    <span className="badge badge-warning" id="cartcount" style={{fontSize:"8px"}}>
                      {user.cart.count}
                    </span>
                  )}
                  <p className="text-decoration-underline">Cart</p>
                </Nav.Link>
              </LinkContainer>
            )}

            {!user.email ? (
              <Link to="/login">
                <i className="fa-solid fa-right-to-bracket text-white"></i>
                <p
                  className="text-decoration-underline"
                  style={{ color: "#fff" }}
                >
                  Login
                </p>
              </Link>
            ) : (
              <Link to="/" className="text-white" onClick={handleLogout}>
                <i className="fa-solid fa-circle-xmark"></i>
                <p>Logout</p>
              </Link>
            )}
          </div>
          ) : ( 
            <div className="d-flex align-items-center justify-content-evenly gap-6" style={{fontSize:"12px"}}>
            <Link to="/dashboard" className="text-white">
              <i className="fa-solid fa-user"></i>
              <p>Dashboard</p>
            </Link>
            <Link to="/new-product" className="text-white">
              <i className="fa-solid fa-pen"></i>
              <p>Create</p>
            </Link>
            <Link to="/user-info" className="text-white">
              <i className="fa-solid fa-circle-info"></i>
              <p>Users</p>
            </Link>
            <Link to="/category/all" className="text-white">
              <i className="fa-solid fa-shop"></i>
              <p>Products</p>
            </Link>


            {!user.email ? (
              <Link to="/login">
                <i className="fa-solid fa-right-to-bracket text-white"></i>
                <p
                  className="text-decoration-underline"
                  style={{ color: "#fff" }}
                >
                  Login
                </p>
              </Link>
            ) : (
              <Link to="/" className="text-white" onClick={handleLogout}>
                <i className="fa-solid fa-circle-xmark"></i>
                <p>Logout</p>
              </Link>
            )}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
