import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import axios from "../axios";

function UserInfo() {
  const [users, setUsers] = useState([]);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  // const [newWalletAddress, setNewWalletAddress] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Wallet Address
  const [btcWalletAddress, setBtcWalletAddress] = useState("");
  const [btcWalletQrCode, setBtcWalletQrCode] = useState("");
  const [ethWalletAddress, setEthWalletAddress] = useState("");
  const [ethWalletQrCode, setEthWalletQrCode] = useState("");
  const [usdtWalletAddress, setUsdtWalletAddress] = useState("");
  const [usdtWalletQrCode, setUsdtWalletQrCode] = useState("");
  const [walletAdded, setWalletAdded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/users/all");
        if (!response.data) {
          throw new Error("Failed to fetch data");
        }
        const userData = response.data;


        
        userData.sort((a, b) => {
          if (a.haveMadePayment && !b.haveMadePayment) {
            return -1;
          } else if (!a.haveMadePayment && b.haveMadePayment) {
            return 1;
          } else {
            if (a.lastPaymentTimestamp && b.lastPaymentTimestamp) {
              return new Date(b.lastPaymentTimestamp) - new Date(a.lastPaymentTimestamp);
            } else if (a.lastPaymentTimestamp) {
              return -1;
            } else if (b.lastPaymentTimestamp) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        setUsers(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserData();
  }, [walletAdded]);

  const handleApprovePayment = async (userId, userEmail) => {
    try {
      // Send a POST request to backend to approve payment using Axios
      const paymentResponse = await axios.post("users/approvePayment", {
        userId,
      });
      console.log(paymentResponse.data.message); // Log success message

      // Send a POST request to backend to send approved email
      const emailResponse = await axios.post("/send-approved-email", {
        email: userEmail,
      });
      console.log(emailResponse.data.message); // Log email sending success message

      // If both requests are successful, show success modal
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error handling payment and sending email:", error);
      setShowDeclinePopup(true);
    }
  };

  const handleDeclinePayment = async (userId, userEmail) => {
    try {
      // Send POST request to /madePaymentFalse endpoint
      await axios.post("users/madePaymentFalse", { userId });
      // Send a POST request to backend to send declined email
      const emailResponse = await axios.post("/send-declined-email", {
        email: userEmail,
      });
      console.log(emailResponse.data.message);
      setShowDeclinePopup(true);
      // Additional actions after POST requests can be added here
      // dispatch(clearCart());
    } catch (error) {
      console.error("Error sending POST requests:", error);
      alert("error declining user");
    }
    console.log(userId);
  };

  const handleCloseDeclinePopup = () => {
    setShowDeclinePopup(false);
  };

  const handleNewWalletAddress = async () => {
    try {
      await axios.post("/wallets/update", {
        btcWalletAddress,
        btcWalletQrCode,
        ethWalletAddress,
        ethWalletQrCode,
        usdtWalletAddress,
        usdtWalletQrCode,
      });
      console.log("Wallet addresses updated successfully");
      setWalletAdded(true);
    } catch (error) {
      console.error("Error updating wallet addresses:", error);
    }
  };

  return (
    <div style={{ padding: "150px 20px 50px", textAlign: "center" }}>
      <div className="Wallet mb-5">
        <h3>Add a new Wallet Address</h3>
        <div className="d-flex flex-column gap-2">
          <input
            type="text"
            value={btcWalletAddress}
            onChange={(e) => setBtcWalletAddress(e.target.value)}
            placeholder="BTC Wallet Address"
          />
          <input
            type="text"
            value={btcWalletQrCode}
            onChange={(e) => setBtcWalletQrCode(e.target.value)}
            placeholder="BTC Wallet QR Code URL"
          />
          <input
            type="text"
            value={ethWalletAddress}
            onChange={(e) => setEthWalletAddress(e.target.value)}
            placeholder="ETH Wallet Address"
          />
          <input
            type="text"
            value={ethWalletQrCode}
            onChange={(e) => setEthWalletQrCode(e.target.value)}
            placeholder="ETH Wallet QR Code URL"
          />
          <input
            type="text"
            value={usdtWalletAddress}
            onChange={(e) => setUsdtWalletAddress(e.target.value)}
            placeholder="Litecoin Wallet Address"
          />
          <input
            type="text"
            value={usdtWalletQrCode}
            onChange={(e) => setUsdtWalletQrCode(e.target.value)}
            placeholder="Litecoin Wallet QR Code URL"
          />
        </div>
        <br />
        <button onClick={handleNewWalletAddress}>
          Update Wallet Addresses
        </button>
      </div>
      <h2>User Information</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "black", color: "white" }}>#</th>
            <th style={{ backgroundColor: "black", color: "white" }}>Name</th>
            <th style={{ backgroundColor: "black", color: "white" }}>Email</th>
            <th style={{ backgroundColor: "black", color: "white" }}>UserId</th>
            <th style={{ backgroundColor: "black", color: "white" }}>Total</th>
            <th style={{ backgroundColor: "black", color: "white" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.email}>
              <td style={{ backgroundColor: "black", color: "white" }}>
                {index + 1}
              </td>
              <td style={{ backgroundColor: "black", color: "white" }}>
                {user.name}
              </td>
              <td style={{ backgroundColor: "black", color: "white" }}>
                {user.email}
              </td>
              <td style={{ backgroundColor: "black", color: "white" }}>
                {user.userId}
              </td>
              <td style={{ backgroundColor: "black", color: "white" }}>
                {user.total}
              </td>
              <td style={{ backgroundColor: "black", color: "white" }}>
                {user.haveMadePayment && (
                  <>
                    <Button
                      variant="success"
                      onClick={() =>
                        handleApprovePayment(user.userId, user.email)
                      }
                      className="m-1"
                    >
                      Approve Payment
                    </Button>
                    <br />
                    <Button
                      variant="danger"
                      onClick={() =>
                        handleDeclinePayment(user.userId, user.email)
                      }
                    >
                      Decline Payment
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        {" "}
        {/* Success modal */}
        <Modal.Header closeButton>
          <Modal.Title>Payment Approved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The payment has been approved successfully. User can now see the
          download button
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeclinePopup} onHide={handleCloseDeclinePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Declined</Modal.Title>
        </Modal.Header>
        <Modal.Body>The payment has been declined.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeclinePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={walletAdded} onHide={() => setWalletAdded(false)}>
        {" "}
        {/* Success modal */}
        <Modal.Header closeButton>
          <Modal.Title>Wallet Added</Modal.Title>
        </Modal.Header>
        <Modal.Body>The Wallet has been updated successfully</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserInfo;
